<script setup lang="ts">
import { useWindowSize, useElementBounding } from '@vueuse/core'

interface Props {
  position?: 'left' | 'right'
  itemsContainerClass?: string
  scrollable?: boolean
  borderless?: boolean
}

withDefaults(defineProps<Props>(), {
  position: 'left',
  scrollable: true,
  borderless: false
})

const items = ref(null)
const bounding = useElementBounding(items)
const { height } = useWindowSize()
const isFullyInViewport = ref(true)

const checkPosition = async () => {
  if (!items.value) { return }
  await nextTick()
  isFullyInViewport.value = height.value > bounding.bottom.value
}

onMounted(() => {
  checkPosition()
})
</script>

<template>
  <div
    ref="items"
    :class="{
      'left-0': position === 'right',
      'right-0': position === 'left',
      [itemsContainerClass as string]: true,
      'overflow-y-auto': scrollable,
      'max-h-60': scrollable,
      'border-black-10': borderless,
      'border-black-100': !borderless,
      'mt-1': isFullyInViewport,
      'bottom-full mb-1': !isFullyInViewport,
    }"
    class="absolute bg-white-100 p-0.5 rounded border border-solid min-w-[15rem] w-full z-20 shadow-large-gray"
  >
    <slot />
  </div>
</template>

<style scoped lang="postcss">

</style>
